<template>
  <div>
      <!-- Header section -->
      <el-row :gutter="0">
          <el-col :span="20" :offset="2">
              <header class="page-header">
                  <el-row :gutter="20" align="middle">
                    <el-col :span="12">
                        <h2>Брэндийн жагсаалт</h2>
                    </el-col>
                    <el-col :span="12">
                        <div class="text-right">
                                <router-link to="/brand-add">
                                    <el-button type="success" class="item">Нэмэх</el-button>
                                </router-link>
                        </div>
                    </el-col>
                  </el-row>
              </header>
              <!-- Core content -->
              <div class="panel tabled">
                  <el-tabs v-model="activeTabStatus">
                      <el-tab-pane label="Бүгд" name="all">
                          <div class="panel-content">
                              <div class="pl20 pr20 table-filter mb10">
                                  <el-row :gutter="10" align="middle" type="flex">
                                      <el-col :span="20">
                                          <el-input placeholder="Хайх" class="input-with-select" @input="onSearch" v-model="search">
                                            <el-button @click="onSearch" icon="el-icon-search" slot="prepend"></el-button>
                                            </el-input>
                                      </el-col>
                                      <el-col :span="4" :offset="4" class="text-right">
                                          <el-dropdown @command="handleSort">
                                              <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                                              <el-dropdown-menu slot="dropdown">
                                                  <el-dropdown-item :command='filter.value' v-for="(filter, indexFilter) in filterData" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                                              </el-dropdown-menu>
                                          </el-dropdown>
                                      </el-col>
                                  </el-row>
                              </div>
                          </div>
                          <div class="panel-content">
                            <el-table
                            :data="brandList"
                            style="width: 100%"
                            v-loading="isLoading"
                            @cell-click="clickCell"
                            >
                            <el-table-column label="Зураг">
                              <template slot-scope="scope">
                                <el-row :gutter="10" align="middle" type="flex">
                                  <el-col :span="12">
                                    <div class="image-holder" style="width: 80px; height: 80px">
                                      <img :src="scope.row.brand_logo">
                                    </div>
                                  </el-col>
                                </el-row>
                              </template>
                            </el-table-column>
                            <el-table-column label="Монгол нэр"
                              prop="brand_monName"
                            ></el-table-column>
                            <el-table-column label="Англи нэр"
                              prop="brand_engName"
                            ></el-table-column>
                            <el-table-column label="Төлөв"
                            >
                              <template slot-scope="scope">
                                <el-tag v-if="scope.row.isActive === true">Идэвхтэй</el-tag>
                                <el-tag type="info" v-else>Идэвхгүй</el-tag>
                              </template>
                            </el-table-column>
                            <el-table-column label="Нийлүүлэгч"
                              prop="supplier_name_mon"
                            ></el-table-column>
                            </el-table>
                            <el-pagination
                              @current-change = "curentPageHandler"
                              @size-change = "sizeChangeHandler"
                              class="text-right mt10"
                              background
                              :current-page.sync="currentPage"
                              :page-size="pageSize"
                              :page-sizes="[5, 20, 50, 100]"
                              layout="total, sizes, prev, pager, next"
                              :total="totalCount">
                            </el-pagination>
                          </div>
                      </el-tab-pane>
                  </el-tabs>
              </div>
          </el-col>
      </el-row>
  </div>
</template>

<script>
import services from '../../../helpers/services'
export default {
  data () {
    return {
      search: '',
      brandList: [],
      activeTabStatus: 'all',
      isLoading: false,
      pageSize: this.$route.query.size ? JSON.parse(this.$route.query.size) : 20,
      currentPage: this.$route.query.page ? JSON.parse(this.$route.query.page) : 1,
      totalCount: 0,
      filterData: [
        {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }
      ]
    }
  },

  async mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.pageFrom = from
    this.pageSize = size
    this.getBrand(size, from, this.search)
  },

  methods: {
    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'brandList', query: { page: item, size: this.pageSize, search: this.search } }).catch(() => {})
      this.getBrand(this.pageSize, (this.pageSize * (this.currentPage - 1)), this.search)
    },
    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'brandList', query: { page: item, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getBrand(this.pageSize, (this.pageSize * (this.currentPage - 1)), this.search)
    },

    onSearch () {
      this.$router.push({ name: 'brandList', query: { page: this.currentPage, size: this.pageSize, search: this.search } }).catch(() => {})
      this.getBrand(this.pageSize, (this.pageSize * (this.currentPage - 1)), this.search)
    },

    sortMethods (method, data) {
      if (method === 'firstChar') {
        data.sort((a, b) => a.brand_monName.localeCompare(b.brand_monName))
      } else if (method === 'lastChar') {
        data.sort((a, b) => b.brand_monName.localeCompare(a.brand_monName))
      } else if (method === 'newest') {
        data.sort((a, b) => b.created_at.localeCompare(a.created_at))
      } else if (method === 'oldest') {
        data.sort((a, b) => a.created_at.localeCompare(b.created_at))
      }
      return data
    },

    handleSort (data) {
      this.search = ''
      this.brandList = this.sortMethods(data, this.brandList)
    },
    getBrand (size, from) {
      this.isLoading = true
      const query = '?search_text=' + this.search + '&size=' + size + '&from=' + from
      services.getBrand(query).then(response => {
        if (response.status === 'success' && response.data) {
          this.isLoading = false
          this.brandList = response.data
          this.totalCount = response.total
        } else {
          this.isLoading = false
        }
      })
    },
    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'editBrand',
          params: {
            brand_id: row.brand_id
          }
        }
      )
    }
  }
}
</script>

<style>

</style>
